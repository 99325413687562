import React, { useEffect } from 'react';
import Flatpickr from "react-flatpickr";
import supportImg from "assets/images/support.png";

import { UpcomingScheduledData } from 'Common/data';

const UpcomingScheduled = () => {

    useEffect(() => {
        document.getElementById('upcomingscheduledflatpickr')?.classList.add('hidden');
        return () => {
            document.getElementById('upcomingscheduledflatpickr')?.classList.remove('hidden');
        };
    }, []);

    return (
        <React.Fragment>
            <div className="col-span-12 md:order-9 lg:col-span-6 lg:row-span-2 xl:col-span-4 xl:row-span-2 2xl:row-span-2 2xl:col-span-3 ">
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-3 text-15 grow">Calender</h6>
                        <Flatpickr
                            id='upcomingscheduledflatpickr'
                            options={{
                                inline: true
                            }}
                            className="w-auto p-1"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UpcomingScheduled;
