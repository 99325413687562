import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from 'Common/TableContainer';
import { Link } from 'react-router-dom';
import { EmployeePerformanceData } from 'Common/data';
import { baseURL,mainUrl, getLoggedUser } from 'helpers/api_helper';
import axios from "axios";
// Icons
import { Search, Trash2, Pencil } from 'lucide-react';
import filterDataBySearch from 'Common/filterDataBySearch';

const EmployeePerformance = () => {

    const [data, setData] = useState(EmployeePerformanceData);
    const [ordersData, setOrdersData] = useState<any[]>([]);

    let user = getLoggedUser()
    useEffect(() => {
        getEmployeeData()
      }, [])

    interface employeeDetails {
        id: string;
        image: string
        full_name: string;
        address: string;
        designation: string;
        email: string;
        phone_number: string;
        agent_code: string;
        status: string;
        applications: string;
        total_referrals: number;
        total_referrals_amount: number;
      }
      const headers = {
        headers: {
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
      const getEmployeeData = () => {
        const url = `${baseURL}retrieve_agents/`
        axios
          .get(url, headers)
          .then((res) => {
            if (res) {
                let employees = JSON.stringify(res)
                const data = JSON.parse(employees)
                setOrdersData(data) 
            } 
          })
          .catch((error) => {
            console.log(error)
          })
      }
      const EmployeeData: employeeDetails[] = ordersData.map(employee => {
        const imageUrl = `${mainUrl}/${employee.profile}`;
        return {
          id: employee.id,
          full_name: employee.full_name, 
          address: employee.address,
          designation: employee.designation,
          email: employee.email,
          phone_number: employee.phone_number,
          agent_code: employee.agent_code,
          applications: '1',
          image: imageUrl,
          status: employee.status,
          total_referrals: employee.total_referrals,
          total_referrals_amount: employee.total_referrals_amount,
          
        }
      });
      

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['employeeId', 'name', 'email', 'designation', 'performance'];
        filterDataBySearch(EmployeePerformanceData, search, keysToSearch, setData);
    };

    const columns = useMemo(() => [
        {
            header: (<input id="productsCheckAll" className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />),
            enableColumnFilter: false,
            enableSorting: true,
            id: "checkAll",
            cell: (cell: any) => {
                return (<input id={cell.row.original.id} className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />);
            }
        },
        {
            header: "Agent Code",
            accessorKey: "agent_code",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <Link to="#">{cell.getValue()}</Link>
                </>
            ),
        },
        {
            header: "Name",
            accessorKey: "full_name",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <div className="flex gap-2">
                    <div className="size-10 bg-green-100 rounded-full dark:bg-green-500/20 shrink-0">
                        <img src={cell.row.original.image} alt="" className="h-10 rounded-full" />
                    </div>
                    <div className="grow">
                        <h6>{cell.getValue()}</h6>
                        <p className="text-slate-500 dark:text-zink-200">{cell.row.original.email}</p>
                    </div>
                </div>
            ),
        },
        {
            header: "Designation",
            accessorKey: "designation",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Total Referals",
            accessorKey: "total_referrals",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <span className={`${cell.getValue() === "Good" ? "text-green-500" : "text-red-500"}`}>
                    {cell.getValue()}
                </span>
            ),
        },
        {
            header: "Referals Amount ($)",
            accessorKey: "total_referrals_amount",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <span className={`${cell.getValue() === "Good" ? "text-green-500" : "text-red-500"}`}>
                    {cell.getValue()}
                </span>
            ),
        },
        {
            header: "Status",
            accessorKey: "isActive",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                cell.getValue() ?
                    <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">Disabled</span> :
                    <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">Active</span>
            ),
        },
        
    ], []
    );

    return (
        <React.Fragment>
            <div className="col-span-12 md:order-8 2xl:col-span-9 card">
                <div className="card-body">
                    <div className="grid items-center grid-cols-1 gap-3 mb-5 xl:grid-cols-12">
                        <div className="xl:col-span-3">
                            <h6 className="text-15">Employee Performance</h6>
                        </div>
                        <div className="xl:col-span-4 xl:col-start-10">
                            <div className="flex gap-3">
                                <div className="relative grow">
                                    <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                                </div>
                                <button type="button" className="bg-white border-dashed shrink-0 text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i> Export</button>
                            </div>
                        </div>
                    </div>
                    <TableContainer
                        isPagination={true}
                        columns={(columns || [])}
                        data={(EmployeeData || [])}
                        customPageSize={5}
                        divclassName="-mx-5 overflow-x-auto"
                        tableclassName="w-full whitespace-nowrap"
                        theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500 w-10"
                        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeePerformance;
