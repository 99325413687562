import React from "react";
import axios from "axios";
import { mainUrl } from "helpers/api_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useFormik as useFormic } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createSelector } from 'reselect';
import { getLoggedUser } from "helpers/api_helper";

const ChangePasswordTabs = () => {
   const userId = getLoggedUser().id
   const user_token = getLoggedUser()
    const changePassword = () => {
        const url = `${mainUrl}loan_statistics/`
        axios
          .post(url)
          .then((res) => {
            if (res) {
                toast.success("Application Rejected ", { autoClose: 2000 });
                return res;   
            } 
          })
          .catch((error) => {
            console.log(error)
          })
      }
      const selectLogin = createSelector(
        (state: any) => state.Register,
        (state: any) => state.Login,
        (register, login) => ({
            user: register.user,
            success: login.success,
            error: login.error
        })
    )
    const headers = {
        headers: {
          Authorization: `Token ${user_token.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
      const { user, success, error } = useSelector(selectLogin)
      const validation: any = useFormic({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,
  
          initialValues: {
              current_password: user.password ,
              new_password: user.password,
              confirm_password: user.password,
          },
          validationSchema: Yup.object({
              current_password: Yup.string().required("Please Enter Your Current Password"),
              new_password: Yup.string().required("Please Enter Your New Password"),
              confirm_password: Yup.string().required("Please Enter Confirm Your New Password"),
          }),
          onSubmit: async (values) => {
            const url = `${mainUrl}accounts/change_password_api/`
            let payload ={
                id: userId,
                current_password: values.current_password,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            }
            axios
            .post(url, payload, headers)
            .then((res) => {
                if (res) {
                    toast.success("Password Changed Successfull", { autoClose: 2000 });
                    return res;   
                } 
            })
            .catch((error) => {
                toast.error("Faild To change password make sure the current password, mew and confirm password are correct", { autoClose: 2000 });
            })
            },
        });
    return (
        <React.Fragment>
             <ToastContainer closeButton={false} limit={1} />
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Changes Password</h6>
                    <form id="passwordForm"
                        onSubmit={(event: any) => {
                            event.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-4">
                                <label htmlFor="inputValueOld" className="inline-block mb-2 text-base font-medium">Old Password*</label>
                                <div className="relative">
                                <input
                                    type="password"
                                    id="current_password"
                                    required
                                    name="current_password"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter Current password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.current_password || ""}
                                />
                                {validation.touched.current_password && validation.errors.current_password ? (
                                    <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.new_password}</div>
                                ) : null}
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="inputValueNew" className="inline-block mb-2 text-base font-medium">New Password*</label>
                                <div className="relative">
                                <input
                                    type="password"
                                    id="new_password"
                                    name="new_password"
                                    required
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter Current password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.new_password || ""}
                                />
                                {validation.touched.new_password && validation.errors.new_password ? (
                                    <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.new_password}</div>
                                ) : null}
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="inputValue" className="inline-block mb-2 text-base font-medium">Confirm Password*</label>
                                <div className="relative">
                                <input
                                    type="password"
                                    id="confirm_password"
                                    name="confirm_password"
                                    required
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter Current password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.confirm_password || ""}
                                />
                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                    <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.confirm_password}</div>
                                ) : null}
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="flex justify-end xl:col-span-6">
                                <button type="submit" className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10">Change Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>

    );
}

export default ChangePasswordTabs;