import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import moment from "moment";

// Image
import overview01 from "assets/images/product/overview-01.png";
import overview02 from "assets/images/product/overview-02.png";

import productImg01 from "assets/images/product/img-01.png";
import productImg09 from "assets/images/product/img-09.png";
import productImg12 from "assets/images/product/img-12.png";
import productImg13 from "assets/images/product/img-13.png";
import userDummy from "assets/images/users/user-dummy-img.jpg";
import { useParams } from 'react-router-dom';
// Icon
import { ShoppingCart, Repeat, HelpCircle, Share2, Store, Star, MapPin, MoreHorizontal, FileEdit, Trash2, Truck, Container, ThumbsUp, ThumbsDown, Tag, CheckCheck } from 'lucide-react';
import { Dropdown } from "Common/Components/Dropdown";
import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";
import axios from "axios";
import { baseURL, mainUrl } from "helpers/api_helper";
// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    getReview as onGetReview,
    addReview as onAddReview,
    updateReview as onUpdateReview,
    deleteReview as onDeleteReview
} from 'slices/thunk';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { getLoggedUser } from "helpers/api_helper";
const Overview = () => {

    const dispatch = useDispatch<any>();
    const { applicationId } = useParams();
    const selectDataList = createSelector(
        (state: any) => state.Ecommerce,
        (state) => ({
            dataList: state.reviews
        })
    );

    const { dataList } = useSelector(selectDataList);

    const [data, setData] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const user = getLoggedUser()

    const header: HeadersInit = {
        Authorization: `Token ${user.token}`,
        'Content-Type': 'application/json',
    };

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: header,
        mode: 'cors',
    };
    const headers = {
        headers: {
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
    // Get Data
    useEffect(() => {
        dispatch(onGetReview());
    }, [dispatch]);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);


    useEffect(() => {
        getLoanDetails()
      }, [])
      interface LoanDetails {
        id:string;
        first_name: string;
        last_name: string;
        phone_number:string;
        id_number:string;
        email:string;
        address:string;
        employment_number: string;
        loan_amount:string;
        loan_tenure: string;
        employment_type:string;
        gross_salary: string;
        net_salary: string;
        bank_name:string;
        account_number:string;
        account_name:string;
        ministry:string;
        branch_name:string;
        branch_code:string;
        next_of_kin_full_name:string;
        next_of_kin_number:string;
        next_of_kin_address:string;
        payslip:string;
        photo:string;
        id_copy:string;
        status:string;
        created_at:string;
        updated_at:string;
      }
      
   
    const [loan_details, setLoanDetails] = useState<LoanDetails | null>(null);

    useEffect(() => {
      getLoanDetails();
    }, []);
  
    const getLoanDetails = () => {
        const url = `${baseURL}loan_application_details/${applicationId}`;
    
        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            if (data && data.length > 0) {
              setLoanDetails(data[0]);
            }
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      };

      const downloadImagesAsZip = async () => {
        if (!loan_details) {
          console.error('Loan details not available');
          return;
        }
    
        const imageUrls = [
          mainUrl + loan_details.payslip,
          mainUrl + loan_details.photo,
          mainUrl + loan_details.id_copy,
        ];
        const imageNames = ['payslip.jpg', 'photo.jpg', 'id_copy.jpg'];
    
        try {
          const blobs = await Promise.all(
            imageUrls.map(async (imageUrl) => {
              const response = await fetch(imageUrl, requestOptions);
              if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.status}`);
              }
              return response.blob();
            })
          );
    
          const filteredBlobs = blobs.filter((blob) => blob !== undefined) as Blob[];
          const zipContent = await combineBlobsIntoZip(filteredBlobs, imageNames);
    
          const link = document.createElement('a');
          link.href = URL.createObjectURL(zipContent);
          link.download = `${loan_details.first_name}_${loan_details.last_name}_images.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading images:', error);
        }
      };
    
      const combineBlobsIntoZip = async (blobs: Blob[], fileNames: string[]): Promise<Blob> => {
        const zipArray = new Uint8Array(0);
    
        for (const blob of blobs) {
          const arrayBuffer = await blob.arrayBuffer();
          const combined = new Uint8Array(zipArray.length + arrayBuffer.byteLength);
          combined.set(zipArray, 0);
          combined.set(new Uint8Array(arrayBuffer), zipArray.length);
          zipArray.set(combined);
        }
    
        return new Blob([zipArray], { type: 'application/zip' });
      };

      const ApproveApplication = () => {
        const url = `${baseURL}approve_application/${applicationId}`
        axios
          .post(url,headers)
          .then((res) => {
            if (res) {
                toast.success("Application Approved ", { autoClose: 2000 });
                // window.location.href = '/apps-ecommerce-orders';
                return res;
                
            } 
          })
          .catch((error) => {
            toast.error("Failed to Approve Application", { autoClose: 2000 });
            return error;
          })
      }
      
    // Delete Modal

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            dispatch(onDeleteReview(eventData.id));
            setDeleteModal(false);
        }
    };
    // 

    // Update Data
    const handleUpdateDataClick = (ele: any) => {
        setEventData({ ...ele });
        setIsEdit(true);
        setShow(true);
    };

    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            reason: (eventData && eventData.reason) || ''
        },
        validationSchema: Yup.object({
            reason: Yup.string().required("Please Enter reason")
        }),

        onSubmit: (values) => {
            let payload = {
                reason: values.reason,
            }
                const url = `${baseURL}reject_application/${applicationId}`
                    axios
                    .post(url, payload, headers)
                    .then((res) => {
                        if (res) {
                            toast.success("Application Rejected ", { autoClose: 2000 });
                            setShow(false);
                            return res;                            
                        } 
                    })
                    .catch((error) => {
                        toast.error("Failed to Reject Application", { autoClose: 2000 });
                        setShow(false);
                        return error;

                    })
        },
    });

    // 
    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData("");
            validation.resetForm();
        }
    }, [show, validation]);

    const StareRating = ({ item }: any) => {
        switch (item) {
            case "5":
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                    </div>
                );
            case "4":
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-line"></i>
                    </div>
                );
            case "3":
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                    </div>
                );
            case "2":
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                    </div>
                );
            case "1":
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                    </div>
                );
            default:
                return (
                    <div className="flex items-center gap-2 mt-4 mb-2 text-yellow-500">
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                        <i className="ri-star-line"></i>
                    </div>
                );
        }
    };

    // Add/Edit Modal
    // const [defaultModal, setDefaultModal] = useState<boolean>(false);
    // const defaultToggle = () => setDefaultModal(!defaultModal);

    return (
        <React.Fragment>
             <ToastContainer closeButton={false} limit={1} />
            <BreadCrumb title='Overview' pageTitle='Products' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
           
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-6">
                    <div className="sticky top-[calc(theme('spacing.header')_*_1.3)] mb-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-12">
                                    <div className="rounded-md md:col-span-12 md:row-span-2 bg-slate-100 dark:bg-zink-600">
                                        <img src={mainUrl+loan_details?.id_copy} alt="" />
                                    </div>
                                    <div className="rounded-md md:col-span-12 bg-slate-100 dark:bg-zink-600">
                                        <img src={mainUrl+loan_details?.photo} alt="" />
                                    </div>
                                    <div className="p-4 rounded-md md:col-span-12 bg-slate-100 dark:bg-zink-600">
                                        <img src={mainUrl+loan_details?.payslip} alt="" />
                                    </div>
                                </div>

                                <div className="flex gap-2 mt-4 shrink-0">
                                    <button type="button" onClick={ApproveApplication} className="w-full bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20">
                                        <CheckCheck className="inline-block size-3 align-middle ltr:mr-1 rtl:ml-1" />
                                        <span className="align-middle">Approve Application</span>
                                    </button>
                                    <button type="button" onClick={toggle} className="w-full text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-red-400/20">Reject Application</button>
                                    <button type="button" onClick={downloadImagesAsZip} className="w-full text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-grren-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/20">Download Application</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-6">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mt-5 mb-3 text-15">Applicant Details </h6>
                            <ul className="flex flex-col gap-2">
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">First Name</span> {loan_details?.first_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Last Name</span> {loan_details?.last_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Email</span> {loan_details?.email}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Phone Number</span> {loan_details?.phone_number}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">ID Number </span> {loan_details?.id_number}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Address</span> {loan_details?.address}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Employment Number</span> {loan_details?.employment_number}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Loan Amount</span> {loan_details?.loan_amount}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Loan Tenure</span> {loan_details?.loan_tenure}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Employment Type</span> {loan_details?.employment_type}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Gross Salary</span> {loan_details?.gross_salary}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Net Salary</span> {loan_details?.net_salary}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Bank Name</span> {loan_details?.bank_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Account Number</span> {loan_details?.account_number}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Account Name</span> {loan_details?.account_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Ministry </span> {loan_details?.ministry}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Branch Code</span> {loan_details?.branch_code}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Branch Name</span> {loan_details?.branch_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Next of Kin Full Name</span> {loan_details?.next_of_kin_full_name}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Next Of Kin Address</span> {loan_details?.next_of_kin_address}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Next of Kin Phone Number</span> {loan_details?.next_of_kin_number}</li>
                                <li><Tag className="inline-block size-4 text-green-500 ltr:mr-1 rtl:ml-1 fill-green-200 dark:fill-green-500/20" /> <span className="font-semibold">Status</span> {loan_details?.status}</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            

            {/* Overview Modal */}
            <Modal show={show} onHide={toggle} modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen lg:xl:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-5 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">{!!isEdit ? "Edit Review" : "Send Message"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-12">
                                <div>
                                    <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" id="textArea" placeholder="Type the message you want to send" rows={6}
                                        name="reason"
                                        onChange={validation.handleChange}
                                        value={validation.values.reason || ""}
                                    ></textarea>
                                    {validation.touched.review && validation.errors.reason ? (
                                        <p className="text-red-400">{validation.errors.reason}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end gap-2 mt-4">
                            <button className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10" onClick={toggle}>Cancel</button>
                            <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Send Message"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Overview;