import { Award, CalendarDays, CircuitBoard, Codesandbox, FileText, LifeBuoy, LocateFixed, Mail, Map, MessageSquare, MonitorDot, PackagePlus, PictureInPicture2, PieChart, RadioTower, ScrollText, Share2, ShoppingBag, Table, Trophy, UserRound,AppWindow, Settings, LogOut } from "lucide-react";

const menuData: any = [
    {
        label: 'menu',
        isTitle: true,
    },
    {
        id: "dashboard",
        label: 'Dashboard',
        link: "/#",
        icon: <MonitorDot />,
    },
    {
        id: "orders",
        label: 'Applications',
        link: "/apps-applications",
        icon: <AppWindow />,
    },
    {
        id: "users",
        label: 'Agents',
        link: "/apps-users-list",
        icon: <UserRound />,
    },
    {
        id: "settings",
        label: 'Settings',
        link: "/pages-account-settings",
        icon: <Settings />,
    },
    {
        id: "logout",
        label: 'Logout',
        link: "/logout",
        icon: <LogOut />,
    },


];

export { menuData };