import React,{useState, useEffect} from 'react';
import { TotalEmployeeChart } from './Charts';
import CountUp from 'react-countup';
import ApplicationReceived from './ApplicationReceived';
import axios from 'axios';
import { baseURL, getLoggedUser } from 'helpers/api_helper';

const Widgets = () => {
    let user = getLoggedUser()
    useEffect(() => {
        getLoanStatistics()
      }, [])
      
      const [statistics, setStatistics] = useState({
        total_loan_amount: 0,
        rejected_loans_amount: 0,
        pending_loans_amount: 0,
        disbursed_loans_amount: 0,
        total_loans:0,
        disbursed_loans:0,
        rejected_loans:0,
        pending_loans: 0,
      });

      const headers = {
        headers: {
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
    const getLoanStatistics = () => {
        const url = `${baseURL}loan_statistics/`
        
        axios
          .get(url, headers)
          .then((res) => {
            if (res) {
                let order_statistics = JSON.stringify(res)
                const data = JSON.parse(order_statistics)
                setStatistics(data)
            } 
          })
          .catch((error) => {
            console.log(error)
          })
      }
    return (
        <React.Fragment>
            <div className="col-span-12 md:order-3 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Total Applications</p>
                            <h5 className="mt-3 mb-4">
                                $<CountUp end={statistics.total_loan_amount} className="counter-value" />
                            </h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="totalEmployee" dataChartColor='["bg-custom-500"]' series={[(statistics.total_loans/statistics.total_loans)*100]} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 md:order-4 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Total Disbursed</p>
                            <h5 className="mt-3 mb-4"> $ <CountUp end={statistics.disbursed_loans_amount} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="totalApplication" dataChartColor='["bg-purple-500"]' series={[(statistics.disbursed_loans/statistics.total_loans)*100]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-green-500">{((statistics.disbursed_loans/statistics.total_loans)*100).toFixed(2)}%</span> Of Total Number Of Applications</p>
                    </div>
                </div>
            </div>

            <ApplicationReceived />

            <div className="col-span-12 md:order-5 2xl:order-6 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Pending Applications</p>
                            <h5 className="mt-3 mb-4"> $<CountUp end={statistics.pending_loans_amount} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="hiredCandidates" dataChartColor='["bg-green-500"]' series={[(statistics.pending_loans/statistics.total_loans)*100]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-green-500">{((statistics.pending_loans/statistics.total_loans)*100).toFixed(2)}%</span> Of Total Number Of Applications</p>
                    </div>
                </div>
            </div>
            <div className="col-span-12 md:order-6 2xl:order-7 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Rejected Applications</p>
                            <h5 className="mt-3 mb-4">$<CountUp end={statistics.rejected_loans_amount} className="counter-value" /></h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="rejectedCandidates" dataChartColor='["bg-red-500"]' series={[(statistics.rejected_loans/statistics.total_loans)*100]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-red-500">{((statistics.rejected_loans/statistics.total_loans)*100).toFixed(2)}%</span> Of Total Number Of Applications</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Widgets;
