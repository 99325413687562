import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
import EmployeePerformance from './EmployeePerformance';
import UpcomingScheduled from './UpcomingScheduled';
import TotalProjects from './TotalProjects';
import UpcomingInterview from './UpcomingInterview';
import RecentPayroll from './RecentPayroll';
import { baseURL, getLoggedUser } from 'helpers/api_helper';
import axios from "axios";

const HRDashboard = () => {
    let user = getLoggedUser()
    let name = user.first_name

    useEffect(() => {
        getLoanStatistics()
      }, [])
      
      const [statistics, setStatistics] = useState({
        total_loan_amount: 0,
        rejected_loans_amount: 0,
        pending_loans_amount: 0,
        disbursed_loans_amount: 0,
        total_loans:0,
        disbursed_loans:0,
        rejected_loans:0,
        pending_loans: 0,
      });

    const headers = {
        headers: {
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
    const getLoanStatistics = () => {
        const url = `${baseURL}loan_statistics/`
        
        axios
          .get(url, headers)
          .then((res) => {
            if (res) {
                let order_statistics = JSON.stringify(res)
                const data = JSON.parse(order_statistics)
                setStatistics(data)
            } 
          })
          .catch((error) => {
            console.log(error)
          })
      }
    return (
        <React.Fragment>
            <BreadCrumb title='Dashboard' pageTitle='Dashboards' />
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
                    <h5 className="mb-2">Welcome {name} 🎉</h5>
                </div>
                <div className="col-span-12 md:order-2 xl:col-span-4 2xl:col-start-9 card">
                    <div className="p-4">
                        <div className="grid grid-cols-3">
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={statistics.total_loans} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Total Applications</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={statistics.disbursed_loans} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Disbursed Loans</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={statistics.pending_loans} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Pending Loans</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Widgets />
                <EmployeePerformance />
                <UpcomingScheduled />
                {/* <TotalProjects /> */}
                {/* <UpcomingInterview /> */}
                {/* <RecentPayroll /> */}

            </div>
        </React.Fragment>
    );
};

export default HRDashboard;
