import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CountUp from 'react-countup';
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {Spin} from 'antd'
// icons
import { Boxes, PackagePlus, Loader, Search, Truck, PackageCheck, PackageX, Plus, RefreshCcw, MoreHorizontal, Trash2, Eye, FileEdit } from 'lucide-react';
import { OrdersOverviewChart } from "./charts";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";

import { Dropdown } from "Common/Components/Dropdown";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";
import axios, { AxiosRequestConfig } from "axios";
// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { baseURL,mainUrl, getLoggedUser } from "helpers/api_helper";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    getOrders as onGetOrders,
    addOrders as onAddOrders,
    updateOrders as onUpdateOrders,
    deleteOrders as onDeleteOrders
} from 'slices/thunk';
import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";

const Orders = () => {

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Ecommerce,
        (state) => ({
            dataList: state.orders
        })
    );

    const { dataList } = useSelector(selectDataList);

    const [data, setData] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [ordersData, setOrdersData] = useState<any[]>([]);
    const user = getLoggedUser()
    // Get Data
    useEffect(() => {
        dispatch(onGetOrders());
    }, [dispatch]);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            dispatch(onDeleteOrders(eventData.id));
            setDeleteModal(false);
        }
    };
    // 

    // Update Data
    const handleUpdateDataClick = (ele: any) => {
        setEventData({ ...ele });
        setIsEdit(true);
        setShow(true);
    };

    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderId: (eventData && eventData.orderId) || '',
            orderDate: (eventData && eventData.orderDate) || '',
            deliveryDate: (eventData && eventData.deliveryDate) || '',
            customerName: (eventData && eventData.customerName) || '',
            paymentMethod: (eventData && eventData.paymentMethod) || '',
            amount: (eventData && eventData.amount) || '',
            deliveryStatus: (eventData && eventData.deliveryStatus) || ''
        },
        validationSchema: Yup.object({
            // orderId: Yup.string().required("Please Enter Id"),
            orderDate: Yup.string().required("Please Enter Date"),
            deliveryDate: Yup.string().required("Please Enter Date"),
            customerName: Yup.string().required("Please Enter Name"),
            paymentMethod: Yup.string().required("Please Enter Payment Method"),
            amount: Yup.string().required("Please Enter Amount"),
            deliveryStatus: Yup.string().required("Please Enter Status")
        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateData = {
                    id: eventData ? eventData.id : 0,
                    ...values,
                };
                // update user
                dispatch(onUpdateOrders(updateData));
            } else {
                const newData = {
                    ...values,
                    id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    orderId: "#TWT50151003" + (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                };
                // save new user
                dispatch(onAddOrders(newData));
            }
            toggle();
        },
    });

    useEffect(() => {
        getLoanStatistics()
      }, [])
      
      const [statistics, setStatistics] = useState({
        total_loan_amount: 0,
        rejected_loans_amount: 0,
        pending_loans_amount: 0,
        disbursed_loans_amount: 0,
        total_loans:0,
        disbursed_loans:0,
        rejected_loans:0,
        pending_loans: 0,
      });

    const headers = {
        headers: {
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        },mode: 'cors',
      }
    const getLoanStatistics = () => {
        const url = `${baseURL}loan_statistics/`
        
        axios
          .get(url, headers)
          .then((res) => {
            if (res) {
                let order_statistics = JSON.stringify(res)
                const data = JSON.parse(order_statistics)
                setStatistics(data)
            } 
          })
          .catch((error) => {
            console.log(error)
          })
      }

      useEffect(() => {
        getApplicationsData()
      }, [])

    interface loanDetails {
        application_id: string;
        created_at: string;
        updated_at: string;
        client_name: string;
        amount: string;
        tenure: string;
        status: string;
      }
      const [loading, setLoading]=useState(false)
      const header: AxiosRequestConfig['headers'] = {
       
          Authorization: `Token ${user.token}`,
          'Content-Type': 'application/json',
        }
      
      const getApplicationsData = () => {
        setLoading(true)
        const url = `${baseURL}all_loan_applications/`
        axios
          .get(url, headers)
          .then((res) => {
            if (res) {
                let applications = JSON.stringify(res)
                const data = JSON.parse(applications)
                setOrdersData(data) 
                setLoading(false)
            } 
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      }
     
      const getFilteredApplicationsData = (type: any) => {
        setLoading(true);
        const url = `${baseURL}filtered_loan_applications/`;
        axios
          .get(url, {
            params: { status: type },
            headers: header
            
          })
          .then((res) => {
            if (res) {
                let applications = JSON.stringify(res)
                const data = JSON.parse(applications)
                setOrdersData(data) 
                setLoading(false)
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    };
    
      const applicationData: loanDetails[] = ordersData?.map(application => {
        return {
         application_id: application.id,
          created_at: application.created_at, 
          updated_at: application.updated_at,
          client_name: application.first_name,
          amount: application.loan_amount,
          status: application.status,
          tenure: application.loan_tenure,
        }
      });
      

    // 
    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData("");
            validation.resetForm();
        }
    }, [show, validation]);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['application_id', 'client_name', 'amount', 'status'];
        filterDataBySearch(dataList, search, keysToSearch, setData);
    };

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tab: any, type: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredOrders = dataList;
            if (type !== "all") {
                filteredOrders = getFilteredApplicationsData(type);
            }
            if (type == "all") {
                filteredOrders = getApplicationsData();
            }

            
            setOrdersData(filteredOrders);
        }
    };

    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case "Disbursed":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">{item}</span>);
            case "SSB Check":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">{item}</span>);
            case "Pending Approval":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-sky-100 border-sky-200 text-sky-500 dark:bg-sky-500/20 dark:border-sky-500/20">{item}</span>);
            case "Waiting Disbursement":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">{item}</span>);
            case "Rejected":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">{item}</span>);
            case "Return":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{item}</span>);
            default:
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">{item}</span>);
        }
    };

    const columns = useMemo(() => [
        {
            header: (
                <div className="flex items-center h-full">
                    <input id="CheckboxAll" className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                </div>
            ),
            enableSorting: false,
            id: "checkAll",
            cell: (cell: any) => {
                return (
                    <div className="flex items-center h-full">
                        <input id={"Checkbox" + cell.row.original.id} className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                    </div>
                );
            }
        },
        {
            header: "Application ID",
            accessorKey: "application_id",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <Link to="#!" className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600">{cell.getValue()}</Link>
                </>
            ),
        },
        {
            header: "Created Date",
            accessorKey: "created_at",
            enableColumnFilter: false
        },
        {
            header: "Updated Date",
            accessorKey: "updated_at",
            enableColumnFilter: false,
        },
        {
            header: "Customer Name",
            accessorKey: "client_name",
            enableColumnFilter: false,
        },
        {
            header: "Loan Amount",
            accessorKey: "amount",
            enableColumnFilter: false,
        },
        {
            header: "Loan Tenure",
            accessorKey: "tenure",
            enableColumnFilter: false,
        },
        {
            header: "Loan Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Status item={cell.getValue()} />
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Dropdown className="relative">
                    <Dropdown.Trigger id="orderAction1" data-bs-toggle="dropdown" className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><MoreHorizontal className="size-3" /></Dropdown.Trigger>
                    <Dropdown.Content placement={cell.row.index ? "top-end" : "right-end"} className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600" aria-labelledby="orderAction1">
                        <li>
                            <Link to={`/application-overview/${cell.row.original.application_id}`} className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"><Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Overview</span></Link>
                        </li>
                    </Dropdown.Content>
                </Dropdown>
            ),
        }
    ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Applications Lists' pageTitle='Applications' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-12">
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-custom-50 text-custom-500 dark:bg-custom-500/20 shrink-0"><Boxes /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                  $  <CountUp end={statistics.total_loan_amount} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Total Applications </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-sky-50 text-sky-500 dark:bg-sky-500/20 shrink-0"><PackagePlus /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    $<CountUp end={statistics.disbursed_loans_amount} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Disbursed Amount</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-last md:col-span-2 2xl:col-span-8 2xl:row-span-3 card 2xl:order-none">
                    <div className="card-body">
                        <h6 className="mb-4 text-gray-800 text-15 dark:text-zink-50">Applications Overview</h6>
                        <OrdersOverviewChart id="ordersOverview" />
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0"><Loader /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={statistics.total_loans} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Total Applications</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-purple-500 rounded-md text-15 bg-purple-50 dark:bg-purple-500/20 shrink-0"><Truck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={statistics.disbursed_loans} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Disbursed Loan</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-green-500 rounded-md text-15 bg-green-50 dark:bg-green-500/20 shrink-0"><PackageCheck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={statistics.pending_loans} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Pending Applications</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-red-500 rounded-md text-15 bg-red-50 dark:bg-red-500/20 shrink-0"><PackageX /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={statistics.rejected_loans} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Rejected Applications</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card" id="ordersTable">
                <div className="card-body">

                    <ul className="flex flex-wrap w-full mt-5 text-sm font-medium text-center text-gray-500 nav-tabs">
                        <li className={`group ${activeTab === "1" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="allOrders" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("1", "all"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">All Applications</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "2" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="pendingApplications" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("2", "Rejected"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Rejected Applications</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "3" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="ssbCheck" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("3", "SSB Check"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">SSB Check</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "4" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="approved" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("4", "Pending Approved"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Pending Approval</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "5" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="disbursed" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("5", "Disbursed"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Disbursed</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "6" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="waitingDisbursement" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("6", "Waiting Disbursement"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Waiting Disbursement</span>
                            </Link>
                        </li>
                    </ul>
                    {data && data.length > 0 ?
                        <TableContainer
                            isPagination={true}
                            columns={(columns || [])}
                            data={(applicationData || [])}
                            customPageSize={10}
                            divclassName="mt-5 overflow-x-auto"
                            tableclassName="w-full whitespace-nowrap"
                            theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                            thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                            tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                            PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                        />
                        :
                        (<div className="noresult">
                            <div className="py-6 text-center">
                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched more than 299+ orders We did not find any orders for you search.</p>
                            </div>
                        </div>)}
                </div>
            </div>

        </React.Fragment>
    );
};

export default Orders;